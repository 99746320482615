import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/SEO';

export default function Template({ data, path }) {
    const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark;

    return (
        <Layout>
            <SEO title="Kompetenzen" />
            <section className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Kompetenzen</h1>
                            <p className="xmsonormal">
                                <span className="competent-title-text">
                                    Einzigartigkeit. Das ist die Basis unserer Arbeit.
                                    <br />
                                    Unser Ziel ist es, jedem Mandanten professionell und individuell zu begegnen.
                                    <br />
                                </span>
                                <span className="competent-title-text">Im Folgenden finden Sie weitere Einzelheiten zu unserer Arbeit. </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="competents">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 sidebar">
                            <ul>
                                {allMarkdownRemark.edges.map(edge => (
                                    <li key={edge.node.frontmatter.path} className={edge.node.frontmatter.path === path ? 'active' : ''}>
                                        <Link to={edge.node.frontmatter.path} className="text-uppercase">
                                            {edge.node.frontmatter.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-12 col-md-8 content">
                            <h3>{frontmatter.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                            <p>&nbsp;</p>
                            <p>
                                <Link to="/kontakt">
                                    <strong>Treten Sie hier mit uns in Kontakt!</strong>
                                </Link>
                            </p>
                            <p>
                                <strong>Die Durchsetzung Ihrer Rechte ist unser Auftrag!</strong>
                            </p>
                            <p>
                                Die Inhalte dieser Homepage sind sorgfältig recherchiert, dennoch kann keine Haftung für die Richtigkeit der veröffentlichten
                                Inhalte übernommen werden. Alle veröffentlichten Beiträge und Rundschreiben unterliegen dem Urheberrechtsschutz.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
            }
        }
        allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___position_kompetenzen }, filter: { fileAbsolutePath: { regex: "^/kompetenzen/" } }) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        position_kompetenzen
                    }
                }
            }
        }
    }
`;
